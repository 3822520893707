import React from "react";
import "./Style/Footer.css";
import Container from "react-bootstrap/Container";
import { BsFacebook, BsInstagram, BsWhatsapp } from "react-icons/bs";
import { ImLocation } from "react-icons/im";
import { Link } from 'react-router-dom'
import { padding } from "@mui/system";


const Footer = () => {
  return (
    <div style={{backgroundColor:"#0066CB"}}>
      <div className="footer">
        <Container>
          <div className="row pt-3">
            <div className="col-lg-6 col-12 my-1" style={{color: "#fff"}}>
              <div className="d-flex justify-content-center">
           <img src="/assests/images/logo2.png" alt="Logo" style={{borderRadius: 10}}/>
           </div>
           <p
            style={{
            fontWeight: 700,
            textAlign: 'center'
        }}
              >
               <ImLocation className="m-2 fs-5" />201, Vardhman Market, Ram Vihar, Delhi-110092
              </p>
              <div className=" d-flex justify-content-center">
              
                  
              </div>
            <div className="px-5 d-flex justify-content-center align-items-center flex-column">
            <p
                style={{
                  fontWeight: 700,
                  marginTop: "1rem"
                }}
              >
               © 2022 Vidushi Infra Power
              </p>
              <p
                style={{
                  fontWeight: 700,
                }}
              >
                <a href="http://sangtechtechnologies.in/" style={{color:"#fff"}}> @ Created by Sangtech Technelogies </a>
              </p>
            </div>
            </div>
            <div className="col-lg-6 d-none d-lg-block col-12 d-flex justify-content-center align-items-center" style={{color: "#fff"}}>
<div className="d-flex flex-column">
<div className="d-flex justify-content-center">
<h3>Our Products</h3>
</div>
<div className="d-flex flex-column align-items-center" >
  
<Link to="/lightning"><p className="m-2 " style={{color:'#fff'}}>Lightning Protection System</p></Link>
<Link to="/surge"><p className="m-2" style={{color:'#fff'}}>Surge Protection Devices</p></Link>
<Link to="/earthing"><p className="m-2" style={{color:'#fff'}}>Maintenance Free Earthing Solutions</p></Link>
<Link to="/technicalServices"><p className="m-2" style={{color:'#fff'}}>Technical Services</p></Link>
<Link to="/Cables"><p className="m-2" style={{color:'#fff'}}>HT & LT Cable & Wire</p></Link>
<Link to="/CableJointing"><p className="m-2"  style={{color:'#fff'}}>Cable Jointing and Termination Kits up to 66 KV</p></Link>
</div>

</div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
