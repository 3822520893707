import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Card from 'react-bootstrap/Card'
import {BsFillArrowRightCircleFill} from 'react-icons/bs'
import { Link } from 'react-router-dom'

const ServiceList = () => {
  return (
    <div>
    <Card>
        <h1 className='text-center'>Our Services</h1>
            <Card>
                <Nav.Link as={Link} to="/lightning" style={{cursor: 'pointer', fontSize: "1.1rem"}}> <BsFillArrowRightCircleFill className='mx-1 my-3' style={{
                    color: "#FF3301"
                }}/> Lightning Protection System</Nav.Link>
                <Nav.Link as={Link} to="/surge" style={{cursor: 'pointer', fontSize: "1.1rem"}}> <BsFillArrowRightCircleFill className='mx-1 my-3' style={{
                    color: "#FF3301"
                }}/> Surge Protection Devices </Nav.Link>
                <Nav.Link as={Link} to="/earthing" style={{cursor: 'pointer', fontSize: "1.1rem"}}> <BsFillArrowRightCircleFill className='mx-1 my-3' style={{
                    color: "#FF3301"
                }}/>Maintenance Free Earthing Solutions</Nav.Link>
                <Nav.Link as={Link} to="/technicalServices" style={{cursor: 'pointer', fontSize: "1.1rem"}}> <BsFillArrowRightCircleFill className='mx-1 my-3' style={{
                    color: "#FF3301"
                }}/>Technical Services</Nav.Link>
                <Nav.Link as={Link} to="/Cables" style={{cursor: 'pointer', fontSize: "1.1rem"}}> <BsFillArrowRightCircleFill className='mx-1 my-3' style={{
                    color: "#FF3301"
                }}/>HT & LT Cable & Wire</Nav.Link>
                <Nav.Link as={Link} to="/CableJointing" style={{cursor: 'pointer', fontSize: "1.1rem"}}> <BsFillArrowRightCircleFill className='mx-1 my-3' style={{
                    color: "#FF3301"
                }}/>Cable Jointing and Termination Kits up to 66 KV</Nav.Link>
            </Card>
    </Card>
    </div>
  )
}

export default ServiceList
