import React from 'react'
import { useMediaQuery} from "@mui/material"
import '../Components/Style/HeroSection.css'
import NavBar from '../Components/NavBar'
import Banner from '../Components/Banner'
import Footer from '../Components/Footer'
import ServiceList from '../Components/ServiceList'
import Container from 'react-bootstrap/Container'
import {BsArrowRightCircleFill} from 'react-icons/bs'


const Lightning = () => {
  const isMobileScreen = useMediaQuery("(max-width: 991px)");

  return (
    <div>
      <Banner />
     <NavBar />
     <div className='position-relative overflow-hidden'>
      <img src="/assests/images/lightning-protection.jpg" alt="Image" width={1500} height={300} style={{
        objectFit: "cover",
        filter: "brightness(50%)",
      }}/>
      <div className='position-absolute' style={{
      left: isMobileScreen ? "15%" : "35%",
        top: "40%"
      }}>
      <h1 className='text-light' style={{
      fontSize: isMobileScreen ? "1.5rem" : "3rem"
    }}>Services / Lightning</h1>
      </div>
     </div>
     <Container className='my-5'>
        <div className="row">
          <div className="col-3" style={{
          display:   isMobileScreen ? "none" : "block"
        }}>
<ServiceList />
          </div>
          <div className="col-lg-9 col-12 justify-content-center">
            <div>
              <div className='authorize d-flex justify-content-center align-items-center ' >
                <h2 className='mx-4'>We are authorized business partner of </h2>
                <img src="/assests/images/dehn.png" alt="logo" width={150} />
              </div>
              <div>
              <h3>LIGHTNING PROTECTION SYSTEM</h3>
              <p style={{fontSize: "1.1rem"}}>Lightning is a natural phenomena involving very high quantum of destructive energy. The lightning cannot be prevented. Effective and reliable protection is the only solution.</p>
              <p style={{fontSize: "1.1rem"}}>In view of the above, it is almost impossible to predict when and where a lightning will strike. Hence it is imperative that we are adequately prepared at all times to protect ourselves against these dangerous natural phenomena..</p>
              <p style={{fontSize: "1.1rem"}}>As we know that Indian Standards on <span style={{fontWeight: '600'}}>“Lightning Protection System”</span> has been upgraded to <span style={{fontWeight: '600'}}>IS/IEC 62305 from old IS 2309</span> and Industry is adopting new standard for designing of Lightning Protection for their infrastructure. Further, much awaited <span style={{fontWeight: '600'}}>'National Building Code of India (NBC) – 2016’,</span> has also beenpublished by ‘Bureau of Indian Standards (BIS)’ and the same is being complied by the Industry.</p>
              </div>
              <div className='d-flex flex-wrap gap-3'>
              <img src='assests/images/v11 (1).png' alt='light-1' width="100%"/>
              </div>
           
            </div>
          </div>
        </div>
        <div className='my-5'>
          <div className='p-4' style={{backgroundColor: "lightgray", borderRadius: "15px"}}>
            <h3 className=''>We offer following solutions / services for External Lightning Protection System :</h3>
          </div>
          <div className='my-2'>
          <p> <BsArrowRightCircleFill  style={{color: "#FF3301", fontSize: "1.1rem"}} />Risk Assessment as per IEC/IS 62305</p>
          <p> <BsArrowRightCircleFill  style={{color: "#FF3301", fontSize: "1.1rem"}} /> Site Survey & Design of the complete Lightning Protection solution as per IS / IEC 62305,
NBC 2016.</p>
          <p> <BsArrowRightCircleFill  style={{color: "#FF3301", fontSize: "1.1rem"}} /> Supply of External Lightning Components –Tested clamps (Natural weather Condition)</p>
          <p> <BsArrowRightCircleFill  style={{color: "#FF3301", fontSize: "1.1rem"}} />Earthing solutions & Bonding – UL listed products for Lightning Protection.</p>
          </div>
          <div className='d-flex g-3 flex-wrap justify-content-center'>
            <img src="assests/images/v13.jpg" alt="photo" />
            <img src="assests/images/v14.jpg" alt="photo" />
            <img src="assests/images/v15.jpg" alt="photo" />
            <img src="assests/images/v16.jpg" alt="photo" />
            <img src="assests/images/v17.jpg" alt="photo" />
            <img src="assests/images/v18.jpg" alt="photo" />
            <img src="assests/images/v19.jpg" alt="photo" />
            <img src="assests/images/v20.jpg" alt="photo" />
            <img src="assests/images/v22.jpg" alt="photo" />
            <img src="assests/images/v23.jpg" alt="photo" />
            <img src="assests/images/v24.png" alt="photo" />
            <img src="assests/images/v25.png" alt="photo" />
            <img src="assests/images/v21.jpg" alt="photo" />
            <img src="assests/images/v12.jpg" alt="photo" />
          </div>
        </div>
      </Container>
    <Footer />
    </div>
  )
}

export default Lightning
