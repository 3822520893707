import React from 'react'
import { Card } from 'react-bootstrap'
import './Style/Footer.css'

const Clients = ({imguri}) => {
  return (
 
   <Card className=' py-3 my-2 mx-3 justify-content-center align-items-center' style={{width: "14rem", height:"10rem"}}>
    <img 
    src={imguri} 
    width={200}
    height={150}
    />
   </Card>
  )
}

export default Clients
