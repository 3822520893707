import React from 'react'
import { useMediaQuery} from "@mui/material"
import NavBar from '../Components/NavBar'
import Banner from '../Components/Banner'
import Footer from '../Components/Footer'
import ServiceList from '../Components/ServiceList'
import Container from 'react-bootstrap/Container'
import {BsArrowRightCircleFill} from 'react-icons/bs'
import {Card} from 'react-bootstrap'


const SurgeProtection = () => {
  const isMobileScreen = useMediaQuery("(max-width: 991px)");
  return (
    <div>
      <Banner />
     <NavBar />
     <div className='position-relative overflow-hidden'>
      <img src="/assests/images/v26.jpg" alt="Image" width={1500} height={300} style={{
        objectFit: "cover",
        filter: "brightness(50%)",
      }}/>
      <div className='position-absolute' style={{
      left: isMobileScreen ? "15%" : "35%",
        top: "40%"
      }}>
      <h1 className='text-light' style={{
      fontSize: isMobileScreen ? "1.5rem" : "3rem"
    }}>Services / Surge Protection</h1>
      </div>
     </div>
     <Container className='my-5'>
        <div className="row">
          <div className="col-3" style={{
          display: isMobileScreen ? "none" : "block"
        }}>
<ServiceList />
          </div>
          <div className="col-lg-9 col-12 justify-content-center">
            <div>
              <div>
              <h3>Surge Protection Device (SPD) :</h3>
              <h5>Need of Surge Protection Devices (SPD) in electrical & electronics system & network: </h5>
            <p> <BsArrowRightCircleFill style={{color: "#FF3301",fontSize: "1.1rem"}} /> Transient activity is believed to account for 80% of all electrically – related downtime.</p>
            <p> <BsArrowRightCircleFill style={{color: "#FF3301",fontSize: "1.1rem"}} /> Lightning accounts for a reasonable amount of insurance claims.</p>
            <p> <BsArrowRightCircleFill style={{color: "#FF3301",fontSize: "1.1rem"}} /> Effective transient voltage suppression equipment can double or triple the life of electrical and electronic equipment.</p>
            <p> <BsArrowRightCircleFill style={{color: "#FF3301",fontSize: "1.1rem"}} /> A systems approach to transient voltage surge suppression can result in dramatic performance in terms of return – on investment.</p>
            <p> <BsArrowRightCircleFill style={{color: "#FF3301",fontSize: "1.1rem"}} /> Transient voltage surge suppression is the most immediately apparent, and the most cost – effective means of improving your power quality. </p>
            <div className='d-flex flex-wrap gap-3'>
              <img src='/assests/images/v24.jpg' alt='light-1' width="400" height='500'/>
              <img src='/assests/images/v26.jpg' alt='light-1' width="400" height='500'/>
            </div>
            <p><span style={{fontSize: "1.2rem", fontWeight: '700'}}>Transient voltage: -</span>voltage transients are defined as short duration <span style={{fontWeight: '600'}}>surges</span> of
electrical energy and are the result of the sudden release of energy previously
stored or induced by other means, such as heavy  <span style={{fontWeight: '600'}}>inductive loads or lightning.</span></p>
<p>
Transient overvoltage are short duration, high magnitude voltage peaks with fast
rising edges, commonly referred to as surges often describes as a “spike”.
Transient voltages can reach up to 6000V on a low voltage consumer network
within millisecond duration.
</p>
              </div>
              <div>
              <p><span style={{fontSize: "1.2rem", fontWeight: '700'}}>Transient voltages come from:-</span>Transients can be generated internally or can come into the facility from external sources.</p>
              </div>
              <div>
              <p><span style={{fontSize: "1.2rem", fontWeight: '700'}}>External sources:-</span>Lightning is the most well-known of the externally generated transients. Most transients are not actually the
result of direct lighting strikes. They are most often “individual” onto conductors as lighting strikes near a
power line. The large electric fields generated during a discharge can couple into the power system creating
induced transients. </p>
<p>A cloud to cloud discharge can generate a 70 Volts per meter electric field. On a Km length of transmission
line this is equal to a 70,000 Volts transients and it didn't even touch the line.</p>
              </div>
              <div>
              <div className='d-flex flex-wrap gap-3'>
              <img src='/assests/images/v27.jpg' alt='light-1' width="400"/>
              <img src='/assests/images/v30.jpg' alt='light-1' width="400"/>
            </div>
              <p><span style={{fontSize: "1.2rem", fontWeight: '700'}}>Other externally generated transients:-</span></p>
            <p> <BsArrowRightCircleFill style={{color: "#FF3301",fontSize: "1.1rem"}} /> Switching of facility load.</p>
            <p> <BsArrowRightCircleFill style={{color: "#FF3301",fontSize: "1.1rem"}} /> Switching of capacitor banks.</p>
            <p> <BsArrowRightCircleFill style={{color: "#FF3301",fontSize: "1.1rem"}} /> Re closure operations and tap changing of transformers</p>
            <p> <BsArrowRightCircleFill style={{color: "#FF3301",fontSize: "1.1rem"}} />Poor or loose connections in the distribution systems</p>
            <p> <BsArrowRightCircleFill style={{color: "#FF3301",fontSize: "1.1rem"}} /> High winds which can blow one power line into another or blow tree limbs into the lines causing arching</p>
            <p> <BsArrowRightCircleFill style={{color: "#FF3301",fontSize: "1.1rem"}} />Opening & closing of disconnects on energized line.</p>
              </div>
              <div style={{background: "yellow", padding: "1rem"}}>
                <h4>We would also like to inform that; NBC 2016 also recommended the use of Surge Protection Device (SPD) for
various systems. The clause of NBC 2016 is mentioned below:</h4>

              </div>
              <div className='my-3'>
                <h4>Standard requirement of SPD’s</h4>
                <Card className='p-3'>
                  <p><span style={{fontSize: "1.1rem", fontWeight: '700'}}>11.6.5.6</span> For small residential buildings, power line SPD at the mains incoming panel will enhance the life of electronic
equipment, such as TV, music system, refrigerators, LED lights, etc.</p>
                  <p><span style={{fontSize: "1.1rem", fontWeight: '700'}}>11.6.5.7</span> For large buildings, power line SPD is required at incoming panel as well as for sub distribution panels based on the
LPZ principle.</p>
                  <p><span style={{fontSize: "1.1rem", fontWeight: '700'}}>11.6.5.8</span> For industrial and commercial buildings, critical and sensitive loads such as drives, PLC’s, automation panels, etc.
require protection with SPD in addition to SPD at incoming power panels and sub-distribution boards. SPDs shall be selected
to meet the requirements of relevant LPZs.</p>
                  <p><span style={{fontSize: "1.1rem", fontWeight: '700'}}>11.6.5.9</span> Lifts, escalators, moving walks and fire panels shall be protected with SPD in control panels. All electrical and
control panels related to safety and security of building shall be protected with appropriate SPDs. </p>
                </Card>
              </div>
              <div className='d-flex flex-wrap gap-3'>
              <img src='/assests/images/v29.jpg' alt='light-1' width="400" height='500'/>
              <img src='/assests/images/v30.jpg' alt='light-1' width="400" height='500'/>
            </div>
            </div>
          </div>
        </div>
      </Container>
    <Footer />
    </div>
  )
}

export default SurgeProtection
