import React from 'react'
import { useMediaQuery} from "@mui/material"
import {Card} from 'react-bootstrap'
import NavBar from '../Components/NavBar'
import Banner from '../Components/Banner'
import Footer from '../Components/Footer'
import ServiceList from '../Components/ServiceList'
import Container from 'react-bootstrap/Container'
import {BsArrowRightCircleFill} from 'react-icons/bs'


const EarthingSolution = () => {
  const isMobileScreen = useMediaQuery("(max-width: 991px)");

  return (
    <div>
      <Banner />
     <NavBar />
     <div className='position-relative overflow-hidden'>
      <img src="/assests/images/earthing.jpg" alt="Image" width={1500} height={300} style={{
        objectFit: "cover",
        filter: "brightness(50%)",
      }}/>
      <div className='position-absolute' style={{
      left: isMobileScreen ? "15%" : "35%",
        top: "40%"
      }}>
      <h1 className='text-light' style={{
      fontSize: isMobileScreen ? "1.5rem" : "3rem"
    }}>Services / Earthing Solutions</h1>
      </div>
     </div>
     <Container className='my-5'>
        <div className="row">
          <div className="col-3" style={{
          display: isMobileScreen ? "none" : "block"
        }}>
<ServiceList />
          </div>
          <div className="col-lg-9 col-12 justify-content-center">
            <div>
              <div>
              <h3>MAINTENANCE FREE EARTHING SOLUTIONS</h3>
            <p> <BsArrowRightCircleFill style={{color: "#FF3301",fontSize: "1.1rem"}} /> The basic objective of a good earthing is to ensure the safety of life and property from shock and fire. </p>
            <p> <BsArrowRightCircleFill style={{color: "#FF3301",fontSize: "1.1rem"}} /> A good grounding system provides a low resistance to remote Earth in order to minimize the
GPR. For most transmission and other large substation, the ground resistance is usually about 1Ω or less. In smaller distribution substation the usually acceptable range is from 1Ω to 5Ω depending on local conditions.</p>
              </div>
            <div>
              <Card className='p-3'>
                <h4>* Why Soil treatment is important:</h4>
              </Card>
           <p>When it is not possible to achieve the desired reduction in ground resistance by adding more grid conductors
or ground rods, the alternate solution as per International Standards as well as IS- 3043:2018 is to do Soil
Treatment by treating the area surrounding the Electrode with Earth enhancement material with resistivity
of less than 0.12 Ωm in an augured hole or around ground conductors in a trench.</p>
                <p>The Earth enhancement material shall be permanent and should not leach any chemicals into the ground.</p>
            </div>
            <div>
            <div className='d-flex flex-wrap gap-3'>
              <img src='/assests/images/v22.jpg' alt='light-1' width="400"/>
              <img src='/assests/images/v23.jpg' alt='light-1' width="400"/>
            </div>
              <Card className='p-3'>
                <h4>* * Our Earthing solutions conforms to IEEE / IS guidelines.</h4>
              </Card>
              <p> <BsArrowRightCircleFill style={{color: "#FF3301",fontSize: "1.1rem"}} /> Our Carbon based backfill compounds are not depended on moisture and are very stable
over a long period. Hence, it is honestly maintenance free. </p>
              <p> <BsArrowRightCircleFill style={{color: "#FF3301",fontSize: "1.1rem"}} /> Our backfill compound complies with the standards IEC 62561-7, IEEE std 80 and IS- 3043:2018 .</p>
              <p> <BsArrowRightCircleFill style={{color: "#FF3301",fontSize: "1.1rem"}} /> It is non-corrosive and non-leachable solution.</p>
              <p> <BsArrowRightCircleFill style={{color: "#FF3301",fontSize: "1.1rem"}} />  Our compound have unique swelling property improves the contact area between
earth rod and backfill compound. </p>
              <p> <BsArrowRightCircleFill style={{color: "#FF3301",fontSize: "1.1rem"}} />  We use UL Listed Copper Bonded Steel Rods for our Earthing Solutions. </p>
              <p> <BsArrowRightCircleFill style={{color: "#FF3301",fontSize: "1.1rem"}} />  We have tested Polly plastic Pit cover. </p>
            </div>
            <div className='d-flex flex-wrap gap-3'>
              <img src='/assests/images/v25.png' alt='light-1' width="400"/>
              <img src='/assests/images/v24.png' alt='light-1' width="400"/>
            </div>
            </div>
          </div>
        </div>
      </Container>
    <Footer />
    </div>
  )
}

export default EarthingSolution

