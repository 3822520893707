import React from 'react'
import Home from './pages/Home'
import Lightning from './pages/Lightning'
import SurgeProtection from './pages/SurgeProtection'
import EarthingSolution from './pages/EarthingSolution'
import Cables from './pages/Cables'
import CableJointing from './pages/CableJointing'
import TechnicalServices from './pages/TechnicalServices'

import {  Routes, Route} from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <div>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/lightning" element={<Lightning />} />
      <Route path="/surge" element={<SurgeProtection />} />
      <Route path="/earthing" element={<EarthingSolution />} />
      <Route path="/Cables" element={<Cables />} />
      <Route path="/CableJointing" element={<CableJointing/>} />
      <Route path="/technicalServices" element={<TechnicalServices />} />
  
      </Routes>
    </div>
  )
}

export default App
