import { useMediaQuery} from "@mui/material"
import React from 'react'
import "./Style/Banner.css"
import {ImLocation} from 'react-icons/im'
import {BsWhatsapp} from 'react-icons/bs'
import { AiFillPhone} from 'react-icons/ai'
import {CgMail} from 'react-icons/cg'

const Banner = () => {

  const isMobileScreen = useMediaQuery("(max-width: 991px)");


  return (
    <div style={{
      display: isMobileScreen ? "none" : "block"
    }}>
      <div className="banner">
        <p className='m-0'> <ImLocation className='mx-2 fs-5 icons'/>201, Vardhman Market, Ram Vihar, Delhi-110092</p>
        <div>
        <div className='m-0'>
          
             <a href="mailto:vidushiinfra@gmail.com"><CgMail className='mx-2 fs-4 icons'/><span  style={{color:"#fff"}}>vidushiinfra@gmail.com</span> 
</a>
             <a href="tel:+91-9811166906"><AiFillPhone className='mx-2 fs-5 icons'/></a> 
            <a href="https://wa.me/9811166906"> <BsWhatsapp className='mx-2 fs-5 icons'/></a>
              +91  9811166906 </div>
        </div>
      </div>
    </div>
  )
}

export default Banner;