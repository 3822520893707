import React from 'react'
import { useMediaQuery} from "@mui/material"

import NavBar from '../Components/NavBar'
import Banner from '../Components/Banner'
import Footer from '../Components/Footer'
import ServiceList from '../Components/ServiceList'
import Container from 'react-bootstrap/Container'
import {BsArrowRightCircleFill} from 'react-icons/bs'


const CableJointing = () => {
  const isMobileScreen = useMediaQuery("(max-width: 991px)");

  return (
    <div>
      <Banner />
     <NavBar />
     <div className='position-relative overflow-hidden'>
      <img src="/assests/images/cable-jointing.jpg" alt="Image" width={1500} height={300} style={{
        objectFit: "cover",
        filter: "brightness(50%)",
      }}/>
      <div className='position-absolute' style={{
      left: isMobileScreen ? "15%" : "35%",
        top: "40%"
      }}>
      <h1 className='text-light' style={{
      fontSize: isMobileScreen ? "1.5rem" : "3rem"
    }}>Services / Cable Jointing</h1>
      </div>
     </div>
     <Container className='my-5'>
        <div className="row">
          <div className="col-3" style={{
          display: isMobileScreen ? "none" : "block"
        }}>
<ServiceList />
          </div>
          <div className="col-lg-9 col-12 justify-content-center">
            <div>
              <div>
              <h3>CABLE JOINTING AND TERMINATION KITS UP TO </h3>
              <p style={{fontSize: "1.1rem"}}>Cable Joints and Terminations are considered the most important link in the Power flow from Generating station to
Consumer premises.</p>
              <p style={{fontSize: "1.1rem"}}>Hence, a reliable and quality Jointing and Terminations kits are essential requirement of any reliable power
System.
</p>
              <p style={{fontSize: "1.1rem"}}>Our Heat Shrink Terminations and Jointing kits are one of the most reliable when it comes to high quality heat shrink
cable
Jointing kits.</p>
              <p style={{fontSize: "1.1rem"}}>We can supply and install up to 66 KV grade</p>
              </div>
              <div className='d-flex flex-wrap gap-3'>
              <img src='/assests/images/v40.png' alt='light-1' width="100%"/>
            </div>
            </div>
          </div>
        </div>
      </Container>
    <Footer />
    </div>
  )
}

export default CableJointing

