import React from 'react'
import { useMediaQuery} from "@mui/material"
import '../Components/Style/HeroSection.css'
import NavBar from '../Components/NavBar'
import Banner from '../Components/Banner'
import Footer from '../Components/Footer'
import ServiceList from '../Components/ServiceList'
import Container from 'react-bootstrap/Container'
import {BsArrowRightCircleFill} from 'react-icons/bs'


const Cables= () => {
  const isMobileScreen = useMediaQuery("(max-width: 991px)");

  return (
    <div>
      <Banner />
     <NavBar />
     <div className='position-relative overflow-hidden'>
      <img src="/assests/images/cables.jpg" alt="Image" width={1500} height={300} style={{
        objectFit: "cover",
        filter: "brightness(50%)",
      }}/>
      <div className='position-absolute' style={{
      left: isMobileScreen ? "15%" : "35%",
        top: "40%"
      }}>
      <h1 className='text-light' style={{
      fontSize: isMobileScreen ? "1.5rem" : "3rem"
    }}>Services / Cables</h1>
      </div>
     </div>
     <Container className='my-5'>
        <div className="row">
          <div className="col-3" style={{
          display: isMobileScreen ? "none" : "block"
        }}>
<ServiceList />
          </div>
          <div className="col-lg-9 col-12 justify-content-center">
            <div>
            <div className='authorize py-2 d-flex justify-content-center align-items-center mb-3 '>
                <h2 className='mx-4'>We are business partner of </h2>
                <img src="/assests/images/gloster.webp" alt="logo" width={80}/>
              </div>
              <div>
              <h3>CABLES & WIRES</h3>
              <p style={{fontSize: "1.1rem"}}>The HT & LT cables are manufactured as per IS 7098 and flexible wires are as per IS 
694 .</p>
              <p style={{fontSize: "1.1rem"}}>It is believed that Electricity faults contributes approximately 56 % of the fire 
accidents in India . Therefore we require to have wires in our houses , industry , malls 
and offices etc. which must have following properties :</p>
<div>
  <p style={{fontSize: "1.1rem"}}> <span style={{textDecoration:'underline', fontWeight: 700}}>Low Halogen</span> (cause of corrosive gases/ damage to health)</p>
  <p style={{fontSize: "1.1rem"}}> <span style={{textDecoration:'underline', fontWeight: 700}}>Flame Retardence</span> (self extinction of flames when source of fire is removed)</p>
  <p style={{fontSize: "1.1rem"}}> <span style={{textDecoration:'underline', fontWeight: 700}}>Reduction in Fire Propagation</span> (no spread of fire from one location to another)</p>
  <p style={{fontSize: "1.1rem"}}> <span style={{textDecoration:'underline', fontWeight: 700}}>Low Smoke Emission</span> (sufficient visibility for escape)</p>
</div>
              </div>
             <div>
             <div className='d-flex flex-wrap gap-3'>
              <img src='/assests/images/v36.jpg' alt='light-1' width="400"/>
              <img src='/assests/images/v37.jpg' alt='light-1' width="400"/>
            </div>
            <p>The domestic wires supplied by us are triple layer ( HR-FRLSH + FRLSH + FRLSH ) 
which protects our customer from fire hazards.</p>
            <p>The copper conductor used by us is pure bright electrolytic grade copper . The PVC 
insulation material sourced is of excellent quality </p>
           

            </div>
            <div className='d-flex flex-wrap gap-3'>
              <img src='/assests/images/v38.png' alt='light-1' width="700"/>
              <img src='/assests/images/v38.jpg' alt='light-1' width="400"/>
              <img src='/assests/images/v39.png' alt='light-1' width="400"/>
            </div>
            </div>
          </div>
        </div>
      </Container>
    <Footer />
    </div>
  )
}

export default Cables


