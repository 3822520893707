import React from "react";
import { useMediaQuery} from "@mui/material"
import {Link} from 'react-router-dom'
import  Card from "react-bootstrap/Card";
import './Style/Footer.css'




const Product = ({imguri, title, desc, link}) => {

  const isMobileScreen = useMediaQuery("(max-width: 991px)");

  return (
    <Link to={`/${link}`} style={{textDecoration: "none"}}>   <Card className="p-2 mx-3 my-2 cardStyle" style={{
      width: isMobileScreen ? "18rem" : "20rem",
color: "black",
height: "18rem",
display: 'flex',
justifyContent: 'center',
alignItems: 'center'

    }}>
      <div className="text-center" >
      <img src={imguri} alt="Imgae" width={220}/>
      </div>
      <h5 className="text-center my-2 fw-semibold">{title}</h5>
      <div className="text-center">
    </div>
    </Card></Link>
  );
};

export default Product;
