import React from 'react'
import { NavDropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Link} from 'react-router-dom'
import {Link as Links} from "react-scroll"

const NavBar = () => {
  return (
    <div className='navbar-fixed-top'>
         <Navbar bg="light" expand="lg" >
      <Container fluid className='px-5'>
        <Navbar.Brand as={Link} to="/">
          <img src="/assests/images/logo2.png" alt="logo" width={50} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/" style={{fontSize: "1.1rem", fontWeight:'500'}} >Home</Nav.Link>
            <NavDropdown title="Services" id="basic-nav-dropdown" style={{fontSize: "1.2rem", fontWeight:'500'}}>
              <NavDropdown.Item as={Link} to="/lightning" style={{fontSize: "1.1rem", fontWeight:'500'}}>Lightning Protection System</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/surge" style={{fontSize: "1.1rem", fontWeight:'500'}}>
              Surge Protection Devices
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/earthing" style={{fontSize: "1.1rem", fontWeight:'500'}}>Maintenance Free Earthing Solutions</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Cables" style={{fontSize: "1.1rem", fontWeight:'500'}}>
              HT & LT Cable & Wire
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/CableJointing" style={{fontSize: "1.1rem", fontWeight:'500'}}>
              Cable Jointing and Termination
Kits up to 66 KV
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/technicalServices" style={{fontSize: "1.1rem", fontWeight:'500'}}>
              Technical Services
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Links} to="about" smooth={true} duration={500} offset={-130} style={{fontSize: "1.1rem", fontWeight:'500', cursor: 'pointer'}} >About</Nav.Link>
            <Nav.Link as={Links} to="contact" smooth={true} duration={500} offset={-60} style={{fontSize: "1.1rem", fontWeight:'500', cursor: 'pointer'}} >contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  )
}

export default NavBar
