import React from 'react'
import { useMediaQuery} from "@mui/material"
import Carousel from 'react-bootstrap/Carousel';
import './Style/HeroSection.css'

const HeroSection = () => {
  const isMobileScreen = useMediaQuery("(max-width: 991px)");
  return (
    <div className='herosection overflow-hidden'>
        <div className="row text-container">
            <div className="col-12" >
    <Carousel style={{
              height: isMobileScreen ? "40vh" : "90vh"
            }}>
      <Carousel.Item>
        <img
          className="d-block"
          src="assests/images/Lightning.jpg"
          alt="First slide"
          style={{objectFit: "cover"}}
          width={1500}
          height = {isMobileScreen ? 360 : 560}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block"
          src="assests/images/SPD.jpg"
          alt="Second slide"
          style={{objectFit: ""}}
          width={1500}
          height={isMobileScreen ? 360 : 560}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block"
          src="/assests/images/pexels-pixabay-258173 (1).jpg"
          alt="Third slide"
          style={{objectFit: "fill"}}
          width={1500}
          height={isMobileScreen ? 360 : 560}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block"
          src="/assests/images/car4.jpg"
          alt="Third slide"
          style={{objectFit: "fill"}}
          width={1500}
          height={isMobileScreen ? 360 : 560}
        />
      </Carousel.Item>
    </Carousel>
            </div>
        </div>
    </div>
  )
}

export default HeroSection
