import React, {useRef} from "react";
import Container from "react-bootstrap/Container";
import { useMediaQuery} from "@mui/material"
import emailjs from '@emailjs/browser';
import { ImLocation2 } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import { BsFillStopwatchFill } from "react-icons/bs";
import {HiArrowNarrowRight} from 'react-icons/hi'
import "./Style/ContactUs.css";

const ContactUs = () => {

  const isMobileScreen = useMediaQuery("(max-width: 991px)");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_91wwnfl', 'template_886szdi', form.current, '8nGOhK0tfEfFet5PN')
      .then((result) => {
          console.log(result.text);
          console.log("Message Sent")
      }, (error) => {
          console.log(error.text);
          console.log("Message Sent")
          e.target.reset()

      });
  };

  return (
    <div id="contact">
        <div className="row">
          <div className=" col-12" >
          <iframe
      style={{ width: "100%", height: "500px" }}
      className="gmap_iframe"
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
      src="https://maps.google.com/maps?width=600&amp;height=543&amp;hl=en&amp;q=201, Vardhman Market, Ram Vihar, Delhi-110092&amp;&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
    ></iframe>

          </div>
          
        </div>
      <Container className="text-center">
<div className="row my-3">
  <div className="col-12 d-flex" style={{flexDirection: isMobileScreen ? "column" : "row"}}>
      <div className="">
              <div className="d-flex">
                <ImLocation2
                  className="fs-3 fw-lighter"
                  style={{ color: "#FF3301" }}
                />{" "}
                <h5 style={{fontSize: "1.3rem"}}>Our Office Address</h5>
              </div>
              <p className=" mx-5 text-start" style={{fontSize: "1.1rem", fontWeight:"400"}}>
              201, Vardhman Market, Ram Vihar, Delhi-110092
              </p>
            </div>
            <div className="div">
              <div className="d-flex">
                <MdEmail
                  className="fs-3 mx-3 fw-lighter"
                  style={{ color: "#FF3301" }}
                />{" "}
                <h5 style={{fontSize: "1.3rem"}}>General Inquiries</h5>
              </div>
              <p className=" mx-5 text-start" style={{fontSize: "1.1rem", fontWeight:"400"}}>
              vidushiinfra@gmail.com
              </p>
            </div>
            <div className="div">
              <div className="d-flex">
                <IoMdCall className="fs-3 mx-3 " style={{ color: "#FF3301" }} />{" "}
                <h5 style={{fontSize: "1.3rem"}}>Call Us</h5>
              </div>
              <p className=" mx-5 text-start" style={{fontSize: "1.1rem", fontWeight:"400"}}>
               +91 9811166906
              </p>
            </div>
         
  </div>
</div>
        <div className="row ">
          <div className="col-lg-6 col-12">
           <form ref={form} onSubmit={sendEmail}>
           <input type="text" name="user_name" placeholder="FullName" />
            <input type="text" name="number" placeholder="Mobile Number" />
            <input type="text" name="user_email" placeholder="Email Address" />
           </form>
          </div>
          <div className="col-lg-6 col-12">
           <form ref={form} onSubmit={sendEmail}>
           <textarea
              name="message"
              placeholder="Your Message..."
              cols="70"
              rows="6"
              className="mt-2 p-3"
            ></textarea>
           </form>
          </div>
        </div>
        <form ref={form} onSubmit={sendEmail}>
        <input type="submit" value="Send" className="btn submit-button" style={{background: "#FF3301", color: "#fff"}}/>
        </form>
      </Container>
    </div>
  );
};

export default ContactUs;
