import React from "react";
import Container from "react-bootstrap/Container";
import {FaCheck} from 'react-icons/fa' 

const About = () => {
  return (
    <Container style={{
    }} id="about">
      <div className="row">
        <div className="col-lg-7 col-12 d-flex flex-column align-items-center justify-content-center">
          <p style={{fontSize:"1.2rem"}}>
          We at VIDUSHI Infra & Power believe in providing solutions to our customers for their requirements 
by offering them quality products from reputed manufacturers and coupling them with our sincere 
and focused service.We are committed towards customer delight all the time. We are not only another vendor to 
our customers but are their extended hands.We are authorized business partner for few established and reputed international as well as 
national brands.Since 2008 we have been able to satisfy our customers by our excellent services. Our
services are used by sectors like Auto, Reality, Hospitals, Power, Infra, Solar, Manufacturing, 
Hotels & Malls, IT Industry etc
          </p>
        <div className="row d-flex justify-content-between">
              <div className=" col d-flex">
            <FaCheck className="mt-1 mx-2 fs-4" style={{
                color: "#3ABC01"
            }}/><p style={{fontSize: "1.2rem"}}>Experience</p>
           </div>
            <div className="col d-flex mx-1">
            <FaCheck className="mt-1 mx-2 fs-4" style={{
                color: "#3ABC01"
            }}/><p style={{fontSize: "1.2rem"}}>Teamwork</p>
            </div>
            <div className="col d-flex mx-1">
            <FaCheck className="mt-1 mx-2 fs-4" style={{
                color: "#3ABC01"
            }}/><p style={{fontSize: "1.2rem"}}>Result</p>
            </div>
        </div>
        </div>
        <div className="col-12 col-lg-5 d-flex justify-content-center align-items-center">
          <img
            src="/assests/images/car1.jpg"
            alt="About Us"
            width={500}
            style={{
              objectFit: "cover"
            }}
          />
        </div>
      </div>
    </Container>
  );
};

export default About;
