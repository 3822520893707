import React from 'react'
import { useMediaQuery} from "@mui/material"
import Banner from '../Components/Banner'
import HeroSection from '../Components/HeroSection'
import NavBar from '../Components/NavBar'
import Product from '../Components/Product'
import About from '../Components/About'
import ContactUs from '../Components/ContactUs'
import Footer from '../Components/Footer'
import  Container  from 'react-bootstrap/Container'
import Clients from '../Components/Clients'

const Home = () => {
  const isMobileScreen = useMediaQuery("(max-width: 991px)");
  return (
    <div style={{width: "100%", overflow:"hidden", margin: 0}}>
<Banner />
      <NavBar />
      <HeroSection />
      <h1 className='text-center my-5 fw-bold' style={{fontSize: isMobileScreen ? "2rem" : "3rem"}}>Our Products & Services</h1>
      <Container className='d-flex flex-wrap' style={{
        justifyContent: isMobileScreen ? "center" : "space-between"
      }}>
        <Product imguri="/assests/images/lightning-protection.jpg" title="Lightning Protection System" link="lightning"/>
        <Product imguri="/assests/images/v24.jpg" title="Surge Protection Devices"   link="surge"/>
        <Product imguri="/assests/images/v22.jpg" title="Maintenance Free Earthing Solutions"   link="earthing"/>
        <Product imguri="/assests/images/v35.jpg" title="Technical Services"  link="technicalServices"/>
        <Product imguri="assests/images/v37.jpg" title="HT & LT Cable & Wire" link="Cables"/>
        <Product imguri="assests/images/v40.png" title="Cable Jointing and Termination Kits up to 66 KV" link="CableJointing"/>
      </Container>
     <div>
     <h1 className='text-center my-5 fw-bold' style={{fontSize: isMobileScreen ? "2rem" : "3rem"}}>About Us</h1>
      <About />
     </div>
     <div>
     <h1 className='text-center my-5 fw-bold' style={{fontSize: isMobileScreen ? "2rem" : "3rem"}}>Our Clients</h1>
      <Container className='d-flex flex-wrap' style={{
        justifyContent: isMobileScreen ? "center" : "space-between"
      }}>
       <Clients imguri="/assests/images/client1.png"/>
       <Clients imguri="/assests/images/client2.png"/>
       <Clients imguri="/assests/images/client3.png"/>
       <Clients imguri="/assests/images/client4.png"/>
       <Clients imguri="/assests/images/client5.webp"/>
       <Clients imguri="/assests/images/client6.png"/>
       <Clients imguri="/assests/images/client7.png"/>
       <Clients imguri="/assests/images/client8.png"/>
       <Clients imguri="/assests/images/client9.png"/>
       <Clients imguri="/assests/images/client10.png"/>
       <Clients imguri="/assests/images/client11.png"/>
       <Clients imguri="/assests/images/client12.jpg"/>
       <Clients imguri="/assests/images/client13.png"/>
       <Clients imguri="/assests/images/client14.png"/>
       <Clients imguri="/assests/images/client15.jpg"/>
       <Clients imguri="/assests/images/client16.jpg"/>
       <Clients imguri="/assests/images/client17.png"/>
       <Clients imguri="/assests/images/client18.png"/>
       <Clients imguri="/assests/images/client19.png"/>
       <Clients imguri="/assests/images/client20.png"/>
      </Container>
     </div>
    
     <div>
     <h1 className='text-center my-5 fw-bold' style={{fontSize: isMobileScreen ? "2rem" : "3rem"}}>Contact Us</h1>
      <ContactUs />
     </div>
      <Footer />
    </div>
  )
}

export default Home
